import { cloneElement } from "react";
import "./breadcrumb.scss";

const Breadcrumb = ({ children = [] }) => {
	const items = children.map((item, i) => cloneElement(item, { key: i, position: i + 1 }, null));

	return <ul className="breadcrumbs">{items}</ul>;
};

export default Breadcrumb;
