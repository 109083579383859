import PropTypes from "prop-types";
import { Fragment } from "react";
import classNames from "classnames";
import IconBreadcrumCheck from "app/pages/.shared/static/icons/IconBreadcrumCheck";
import "./breadcrumb.scss";

const BreadcrumbItem = ({ label, isActive, isDisabled, position, isPassed }) => {
	const breadcrumbClass = classNames({
		"breadcrumbs-item": true,
		"breadcrumbs-item--active": isActive,
		"breadcrumbs-item--disabled": isDisabled,
		"breadcrumbs-item--passed": isPassed,
	});

	return (
		<Fragment>
			<li className={breadcrumbClass} data-testid="breadcrumbs-item">
				<div className="breadcrumbs-item__position">
					{isPassed ? <IconBreadcrumCheck height={8} width={16} /> : position}
				</div>
				<span className="breadcrumbs-item__label breadcrumbs-item__label">{label}</span>
			</li>
			<div className="breadcrumbs-item__spacer" />
		</Fragment>
	);
};

BreadcrumbItem.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	isActive: PropTypes.bool,
	isPassed: PropTypes.bool,
	isDisabled: PropTypes.bool,
	position: PropTypes.number,
};

BreadcrumbItem.defaultProps = {
	onClick: () => {},
};

export default BreadcrumbItem;
